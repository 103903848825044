@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
}



.liPara {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
  color: #32425c;
  font-size: 1em;
  margin-bottom: 15px;
  cursor: pointer;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .liPara {
    font-size: 0.5em;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 568px) and (max-width: 767px) {
  .liPara {
    font-size: 0.5em;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .liPara {
    font-size: 0.875em;
    margin-bottom: 10px;
  }
}
.liPara:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 25px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  background: #0d2169;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.liPara:hover:after {
  width: 100%;
  left: 0;
}
.activeHeader::after {
  background: none repeat scroll 0 0 transparent;
  bottom: 25px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  background: #0d2169;
  transition: wideskNavdth 0.3s ease 0s, left 0.3s ease 0s;
  width: 100%;
}
.logoAv {
  position: absolute;
  top: 25%;
  margin-bottom: 10px;
  width: 150px;
}
@media only screen and (max-width: 1023px) {
  .logoAv {
    width: 100px;
  }
}
@media only screen and (min-width: 1024px) {
  .deskdiv {
    position: absolute;
    left: 45%;
    transform: translateX(-50%);
    top: 5px;
  }
  .logoR {
    position: absolute;
    top: 30%;
    right: 0px;
  }
  .ipadMobNav {
    display: none;
  }
  .deskNav {
    display: block;
  }
  #rightDiv {
    margin-right: 30px;
  }
  .mainDIv {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #fff;
    height: 60px;
  }
  .mainDIv ul {
    display: flex;
    list-style: none;
  }
  .mainDIv ul li {
    cursor: pointer;
    text-decoration: none;
  }
  .setsidenavOpenOverlayNone {
    display: none;
    overflow: hidden;
  }
  .menu-toggle {
    display: none;
    overflow: hidden;
  }
  .desktopView {
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .ipadMobNav {
    display: block;
  }
  .deskNav {
    display: none;
  }
  .mainDIv {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #fff;
    height: 50px;
  }
  .setsidenavOpenOverlayNone {
    display: block;
    overflow: hidden;
  }
  .desktopView {
    display: none;
    overflow: hidden;
  }
  .menu-toggle {
    position: absolute;
    right: 10px;
    top: 25px;
    cursor: pointer;
  }
  .hamburger {
    content: "";
    display: block;
    background: #32425c;
    height: 3px;
    width: 1em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
    position: relative;
    margin-right: 30px;
  }
  .hamburger::before,
  .hamburger::after {
    content: "";
    display: block;
    background: #32425c;
    height: 3px;
    width: 1em;
    border-radius: 2px;
    transition: all ease-in-out 500ms;
    position: relative;
    margin-right: 30px;
  }
  .hamburgerOpen,
  .hamburgerOpen::before,
  .hamburgerOpen::after {
    transition: all ease-in-out 500ms;
    transform: rotate(45deg);
    content: "";
    display: block;
    background: #32425c;
    height: 3px;
    width: 1em;
    border-radius: 2px;
    margin-right: 30px;
  }
  .hamburger::before {
    transform: translateY(-7px);
  }
  .hamburger::after {
    transform: translateY(4px);
  }
  .hamburgerOpen::before {
    opacity: 0;
  }
  .hamburgerOpen::after {
    transform: translateY(-3px) rotate(-90deg);
  }
  .setsidenavOpenOverlayNone {
    position: absolute;
    background-color: #fff;
    height: 100%;
    width: 0%;
    z-index: 100;
    right: 0px;
    top: 60px;
    animation: menuAnima2 1s forwards;
    overflow: hidden;
  }
  .setsidenavOpenOverlay {
    position: absolute;
    background-color: #fff;
    height: 800px;
    width: 50%;
    z-index: 100;
    right: 0px;
    top: 50px;
    animation: menuAnima 1s forwards;
  }
  @keyframes menuAnima {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0%);
    }
  }
}
@media only screen and (max-width: 1023px) and (max-width: 567px) {
  .hamburger {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 567px) {
  .hamburger::before,
  .hamburger::after {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1023px) and (max-width: 567px) {
  .hamburgerOpen,
  .hamburgerOpen::before,
  .hamburgerOpen::after {
    margin-right: 20px;
  }
}

